*  {FONT-FAMILY: "Century Gothic", "Trebuchet MS", "Avant Garde", "Georgia", "Verdana", "Helvetica", "Tahoma"}


.App {
  text-align: center;
  margin-top: 20px;
}

section::before {
    /* content: ""; */
    /* display: block; c */
    /* height: 0px;  Adjust this to match your navbar's height */
    /* margin: 0px 0 0; This negative margin is the same value as the height */
}

/* Add this CSS to your stylesheet */
.nav-link.active {
    font-weight: bold;
    /* Add any additional styles to highlight the active link */
  }

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
